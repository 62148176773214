import styled, {css} from 'styled-components';

export const backgroundColor = css`
  ${props =>
    props.bgColor &&
    `background-color: ${props.theme.colors[props.bgColor] || props.bgColor};`}
`;

export const center = css`
  ${props => props.center && 'text-align: center;'}
`;
export const color = css`
  ${props => props.color && `color: ${props.theme.colors[props.color] || props.color};`}

  p {
    ${props => props.color && `color: ${props.theme.colors[props.color] || props.color};`}
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const Form = styled.form.attrs({className: 'form-type-material'})``;

//Cards
export const Card = styled.div.attrs({className: 'card'})``;
export const CardTitle = styled.header.attrs({className: 'card-title'})`
  a {
    color: ${props => props.theme.colors.link};
  }
`;
export const CardTitleH2 = styled.h2.attrs({className: 'card-title'})`
  a {
    color: ${props => props.theme.colors.link};
  }
`;
export const CardBody = styled.div.attrs({className: 'card-body'})``;

export const Container = styled.div.attrs({className: 'container'})`
  ${center}
  ${props => props.defaultPadding && 'padding: 25px 15px;'}
  ${props => props.heroPadding && 'padding: 70px 15px;'}
  ${props =>
    props.hero &&
    `
    padding: 70px 15px;
    
    h1 {
      font-size: 48px;
      line-height: 1.5;
    }
    
    p {
      font-size: 18px;
      line-height: 1.5;
    }
  `}
`;

export const ColorBox = styled.div`
  ${backgroundColor}
  ${color}
`;

export const P = styled.p`
  ${props =>
    props.l &&
    `
 font-size: 18px; 
 margin-bottom: 18px; 
 line-height: 28px;`}
`;

export const Wave = styled.div`
  background-image: url(/svg/wave.svg);
  background-color: #f5f6fa;
  ${backgroundColor}
  height: 24px;
  ${props =>
    props.flip &&
    css`
      transform: rotate(180deg);
    `}
`;
