import React, {Component, Fragment} from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import Icon from 'components/icons';
import {getLocation} from 'utils';
const defaultMargin = '10px';
const defaultBorderRadius = '2px';
const iconCSS = css`
  ${props =>
    props.hasChildren &&
    `
  i,
  svg {
    margin-right: 3px;
  }
    `}
`;

const stylePropsCSS = css`
  ${props => props.marginLeft && `margin-left: ${defaultMargin};`}
  ${props => props.marginRight && `margin-right: ${defaultMargin};`}
  ${props => props.marginBottom && `margin-bottom: ${defaultMargin};`}
  ${props => props.transform && `transform: ${props.transform};`}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props =>
    props.small &&
    `
    padding: 2px 8px;
  `}
  ${props => `visibility: ${props.visible ? 'visible' : 'hidden'};`}
`;

const Anchor = styled.a`
  ${iconCSS}
  ${stylePropsCSS}
`;
//Gets rid of props error when a prop does not belong to link
const RouterButton = styled(props => <Link {...props} />)`
  ${iconCSS}
  ${stylePropsCSS}
`;
const StandardButton = styled.button`
  ${iconCSS}
  ${stylePropsCSS}
  &:disabled {
    opacity: 0.75;
  }
`;

export const ButtonGroup = styled.div`
  white-space: nowrap;

  ${StandardButton} {
    border-radius: 0;
    ${props => props.center && 'margin-left: 0 auto; margin-right: 0 auto;'}
    &:first-child {
      border-radius: ${defaultBorderRadius} 0 0 ${defaultBorderRadius};
    }
    &:last-child {
      border-radius: 0 ${defaultBorderRadius} ${defaultBorderRadius} 0;
    }
  }
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${defaultMargin};
    `}

  ${props =>
    props.block &&
    css`
      display: flex;
      ${StandardButton} {
        flex: 1 1 auto;
      }
    `}
`;

class Button extends Component {
  get styleProps() {
    const {
      marginLeft,
      marginRight,
      marginBottom,
      visible,
      transform,
      margin,
      children,
      small,
    } = this.props;

    return {
      small,
      hasChildren: !!children,
      marginLeft,
      marginRight,
      marginBottom,
      visible,
      transform,
      margin,
    };
  }
  get className() {
    const {className, primary, secondary, bold, block, danger} = this.props;
    let str = '';

    if (primary) {
      str = `${str} btn-primary`;
    }
    if (secondary) {
      str = `${str} btn-secondary`;
    }
    if (bold) {
      str = `${str} btn-bold`;
    }
    if (block) {
      str = `${str} btn-block`;
    }

    if (danger) {
      str = `${str} btn-danger`;
    }

    str = str ? `btn ${str}` : '';

    return `${str} ${className}`;
  }
  get children() {
    const {children, icon} = this.props;
    if (!icon) {
      return children;
    }

    return (
      <Fragment>
        <Icon icon={icon} />
        {children}
      </Fragment>
    );
  }
  get button() {
    const {type, ariaLabel, ariaExpanded, ariaHasPopup, title, disabled} = this.props;
    return (
      <StandardButton
        onClick={this.handleClick}
        className={this.className}
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        aria-haspopup={ariaHasPopup}
        title={title}
        disabled={disabled}
        type={type}
        {...this.styleProps}
      >
        {this.children}
      </StandardButton>
    );
  }
  get link() {
    const {to, ariaLabel, title} = this.props;
    return (
      <RouterButton
        className={this.className}
        to={to}
        aria-label={ariaLabel}
        title={title}
        selected={getLocation().pathname === to}
        {...this.styleProps}
      >
        {this.children}
      </RouterButton>
    );
  }
  get anchor() {
    const {href, title, ariaLabel} = this.props;
    return (
      <Anchor
        onClick={this.handleClick}
        className={this.className}
        href={href}
        title={title}
        aria-label={ariaLabel}
        {...this.styleProps}
      >
        {this.children}
      </Anchor>
    );
  }
  handleClick = e => {
    const {handleClick, anchor} = this.props;

    if (anchor) {
      e.preventDefault();
    }

    if (handleClick) {
      handleClick(e);
    }
  };
  render() {
    const {anchor, to} = this.props;
    if (anchor) {
      return this.anchor;
    }

    if (to) {
      return this.link;
    }

    return this.button;
  }
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaHasPopup: PropTypes.bool,
  ariaExpanded: PropTypes.bool,
  icon: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  transform: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  bold: PropTypes.bool,
  block: PropTypes.bool,
  small: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
  marginBottom: PropTypes.bool,
  visible: PropTypes.bool,
  anchor: PropTypes.bool,
  handleClick: PropTypes.func,
};

Button.defaultProps = {
  children: null,
  title: '',
  className: '',
  ariaLabel: '',
  icon: '',
  to: '',
  href: '#',
  type: 'button',
  transform: '',
  margin: '',
  bold: false,
  block: false,
  small: false,
  primary: false,
  secondary: false,
  danger: false,
  marginLeft: false,
  marginRight: false,
  marginBottom: false,
  visible: true,
  anchor: false,
  handleClick: null,
};

export default Button;
