import React from 'react';
import styled, {css} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faGoogle} from '@fortawesome/free-brands-svg-icons/faGoogle';
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter';
//Material UI
//export {default as PersonOutlineIcon} from '@material-ui/icons/PersonOutline';

const fa = {
  faFacebookF,
  faGoogle,
  faTwitter,
  faSignInAlt,
  faSignOutAlt,
  faEye,
  faTrashAlt,
  faSave,
};

const I = styled.i`
  ${({fontSize}) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `}
`;

export const IconFA = ({icon}) => <FontAwesomeIcon icon={fa[icon]} />;
export const IconTI = ({icon, fontSize}) => (
  <I className={`ti-${icon}`} fontSize={fontSize} />
);

const Icon = ({icon, fontSize}) => {
  if (icon.substring(0, 2) === 'fa') {
    return <FontAwesomeIcon icon={fa[icon]} />;
  }

  return <I className={`ti-${icon}`} fontSize={fontSize} />;
};
export default Icon;
